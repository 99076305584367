import React, { useState, useEffect, useContext } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { useRouter } from 'next/router';

import { withTranslation } from 'language/i18n';
import { CallTo } from 'components/common';
import { newsletterFormScrollRef } from 'components/partials/footer/FooterForm';
import { getMachinery } from 'models/machinery';
import { MACHINE_STATE, SOCKET_EMIT, SOCKET_LISTEN } from 'constants/common';
import maskAmount from 'utils/maskAmount';
import { getUserDeposit } from 'models/payment';
import socketInstance from 'utils/socket.io';
import { StatusContext } from 'contexts/statusContext';
import AccordionMenuElement from './AccordionMenuElement';
import LimitIncreaseModal from '../headerDesktop/limitIncreaseModal';

const HeaderMobile = ({
  /*  changeLanguage, language, */ t, userData, isLoggedIn, logout, blackHeader,
}) => {
  const router = useRouter();
  const { statusData } = useContext(StatusContext);
  const [menuVisible, setMenuVisible] = useState(false);
  const [list, setList] = useState({});
  const [activeAuctionsCount, setActiveAuctionsCount] = useState(0);
  const [limitIncreaseModalVisible, setLimitIncreaseModalVisible] = useState(false);
  const [userDepositBalance, setUserDepositBalance] = useState(0);
  const [userBuyingLimit, setUserBuyingLimit] = useState(0);

  useEffect(() => {
    getMachineryFromApi();
    if (document) {
      const body = document.getElementsByClassName('body');
      if (menuVisible) {
        body[0]?.classList?.add('stopScrolling');
      } else {
        body[0]?.classList?.remove('stopScrolling');
      }
    }
  }, [menuVisible]);

  const getMachineryFromApi = async () => {
    try {
      const { machines, filters } = await getMachinery(`state=${MACHINE_STATE.WAITING_EVENT_DATE}`);

      if (!_.isEmpty(machines)) {
        const groupedBy = _.chain(machines).sortBy('event.startDate').filter((machine) => moment().isBefore(moment(machine.event.startDate))).groupBy((machine) => moment(machine.event.startDate).format('DD MMMM'))
          .value();
        setList(groupedBy);
      }

      setActiveAuctionsCount(filters.SALE_STATES.IN_EVENT + filters.SALE_STATES.WAITING_EVENT_DATE);
    } catch (error) {
      console.log('getMachineryFromApiErr', error);
    }
  };

  useEffect(() => {
    const { socket } = socketInstance;
    if (!_.isEmpty(socket) && statusData.socketConnected) {
      socket.emit(SOCKET_EMIT.JOIN_ROOM, userData.id);

      const onBuyingLimit = () => {
        console.log(`Socket event received: ${SOCKET_LISTEN.BUYING_LIMIT}`);
        getUserDepositFromApi();
      };
      socket.on(SOCKET_LISTEN.BUYING_LIMIT, onBuyingLimit);

      return () => {
        socket.emit(SOCKET_EMIT.LEAVE_ROOM, userData.id);
        socket.off(SOCKET_LISTEN.BUYING_LIMIT, onBuyingLimit);
      };
    }

    return () => {};
  }, [statusData.socketConnected, userData?.id]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserDepositFromApi();
    }
  }, [isLoggedIn]);

  const getUserDepositFromApi = async () => {
    try {
      const userDepositInformation = await getUserDeposit();
      setUserDepositBalance(userDepositInformation.userDepositBalance);
      setUserBuyingLimit(userDepositInformation.userBuyingLimit);
    } catch (error) {
      console.log('getUserDepositFromApiErr', error);
    }
  };

  return (
    <header className={`im-header ${blackHeader ? 'im-header--backgrounds' : ''} js-header-sticky`}>
      <div className="header-mobile d-lg-none">
        <Link href="/">
          <a className="logo logo--mobile d-block" onClick={() => setMenuVisible(false)}>
            <img src="/image/logo-siyah.svg" alt="" />
          </a>
        </Link>
        <button
          type="button"
          className="im-button header-mobile__menu-button"
          onClick={() => setMenuVisible(!menuVisible)}
        >
          {menuVisible ? 'KAPAT' : 'MENU'}
        </button>
      </div>
      <div className={`header-mobile-menu ${menuVisible && 'header-mobile-menu--active'}`}>
        {
          isLoggedIn
            ? (
              <div className="header-mobile-menu__head">
                <div>
                  <div>Merhaba,</div>
                  <div className="fw-900">{`${_.result(userData, 'name')} ${_.result(userData, 'surname')}`}</div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => logout()}
                  >
                    {t('logout')}
                  </button>
                </div>
              </div>
            )
            : (
              <div className="header-mobile-menu__head header-mobile-menu__head--not-log-in">
                <Link href="/login">
                  <a
                    className="im-button im-button--transparent im-button--fluid im-button--center im-button--no-gutter im-button--fluid-height"
                    onClick={() => setMenuVisible(false)}
                  >
                    <span className="text-gray12">{`${t('register')} / ${t('login')}`}</span>
                  </a>
                </Link>
              </div>
            )
        }
        {
          isLoggedIn
            && (
              <div style={{ padding: '16px 30px' }}>
                <div className="row align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="col-auto pr-0">
                      <svg className="wh-25">
                        <use xlinkHref="/image/icons.svg#ic-auction2" />
                      </svg>
                    </div>
                    <div className="col">
                      <div className="fw-900 fs-16 mb-1">{maskAmount(userBuyingLimit)}</div>
                      <div className="fs-12">Teklif Üst Limitiniz</div>
                    </div>
                  </div>
                  <div className="col-auto">
                    {userDepositBalance > 0
                      ? (
                        <a
                          className="im-button im-button--center im-button--gutter-10 im-button--mw-110 im-button--size-35 im-button--outline-yellow2 fs-12 fw-500"
                          onClick={() => {
                            setMenuVisible(false);
                            setLimitIncreaseModalVisible(true);
                          }}
                        >
                          Limiti Artır
                        </a>
                      ) : (
                        <Link href="/payment/deposit">
                          <a
                            className="im-button im-button--center im-button--gutter-10 im-button--mw-110 im-button--size-35 im-button--outline-yellow2 fs-12 fw-500"
                            onClick={() => setMenuVisible(false)}
                          >
                            Teminat Bedeli Yatır
                          </a>
                        </Link>
                      )}
                  </div>
                </div>
                <hr className="solid" style={{ marginBottom: '0px' }} />
              </div>
            )
        }
        <div className="header-mobile-menu__body">
          <nav className="header-mobile-menu__mobile-nav">
            <Link href="/">
              <a onClick={() => setMenuVisible(false)}>{t('homepage')}</a>
            </Link>
            <Link href="/profile">
              <a onClick={() => setMenuVisible(false)}>Hesabım</a>
            </Link>
            <AccordionMenuElement title="Yaklaşan Açık Artırmalar">
              {
                _.map(Object.keys(list), (value) => (
                  <a
                    key={value}
                    className="d-flex align-items-center px-4 pt-4"
                    onClick={() => {
                      setMenuVisible(false);
                      router.push({
                        pathname: '/machinery',
                        query: {
                          state: MACHINE_STATE.WAITING_EVENT_DATE,
                        },
                      });
                    }}
                  >
                    <div>
                      <div className="mb-1">
                        <span className="fs-13 fw-900 text-gray4">{value}</span>
                      </div>
                    </div>
                    <div className="ml-auto">
                      <span className="fs-13">{`( ${list[value].length} makina )`}</span>
                    </div>
                  </a>
                ))
              }
              <a
                className="d-flex align-items-center px-4 pt-4"
                onClick={() => {
                  setMenuVisible(false);
                  router.push({
                    pathname: '/machinery',
                    query: {
                      state: MACHINE_STATE.IN_EVENT,
                    },
                  });
                }}
              >
                <div className="mb-1">
                  <span className="fs-13 fw-900 text-gray4">Aktif Açık Artırmalar</span>
                </div>
                <div className="ml-auto">
                  <span className="fs-13">{`( ${activeAuctionsCount} makina )`}</span>
                </div>
              </a>
              <div className="p-3">
                <div className="separator separator--horizontal mx-0" />
              </div>
              {newsletterFormScrollRef?.current && (
                <button
                  type="button"
                  className="im-button im-button--transparent im-button--no-gutter im-button--fluid im-button--auto-height im-button--center px-4 pb-4"
                  onClick={() => {
                    setMenuVisible(false);
                    setTimeout(() => {
                      newsletterFormScrollRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }, 100);
                  }}
                >
                  <span className="fs-13 fw-900 text-gray4">Gelecek Açık Artırmalardan Haberdar Ol</span>
                </button>
              )}
            </AccordionMenuElement>
            {
              userData?.isBuyer ? (
                <Link href="/machine-sales">
                  <a onClick={() => setMenuVisible(false)}>Makinamı Satmak İstiyorum</a>
                </Link>
              ) : (
                <Link href="/machinery">
                  <a onClick={() => setMenuVisible(false)}>Açık Artırmaya Katıl</a>
                </Link>
              )
            }
            <Link href="/content/buying/before-bidding">
              <a onClick={() => setMenuVisible(false)}>{t('buying')}</a>
            </Link>
            <Link href="/content/selling/auction-process">
              <a onClick={() => setMenuVisible(false)}>{t('selling')}</a>
            </Link>
            <Link href="/about-us/">
              <a onClick={() => setMenuVisible(false)}>{t('aboutWith', { text: 'iyimakina.com' })}</a>
            </Link>
            <Link href="/faq/buyers">
              <a onClick={() => setMenuVisible(false)}>{t('faq')}</a>
            </Link>
            <Link href="/contact-us">
              <a onClick={() => setMenuVisible(false)}>{t('contactUs')}</a>
            </Link>
          </nav>
        </div>
        <div className="header-mobile-menu__bottom">
          <div className="header-mobile-menu__bottom__left">
            <div className="mr-2">
              <i className="far fa-phone fs-30" />
            </div>
            <div>
              <div className="fs-12 fw-900">{t('callUs')}</div>
              <CallTo className="fs-12 fw-400" tel="+90 (850) 885 46 25" />
            </div>
          </div>
          <div className="header-mobile-menu__bottom__right">
            <div className="mr-2">
              <i className="far fa-globe fs-30" />
            </div>
            <div>
              <div className="fs-12 fw-400">
                Türkçe
              </div>
              {/* <div className="fs-12 fw-900">Dil Seçimi</div>
              <div className="fs-12 fw-400">
                <select
                  className="js-language-select"
                  name="Language"
                  onChange={() => changeLanguage()}
                >
                  <option value="">Seçiniz</option>
                  <option selected={language === 'tr'} value="tr">Türkçe</option>
                  <option selected={language === 'en'} value="en">İngilizce</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <LimitIncreaseModal
        visible={limitIncreaseModalVisible}
        setVisible={setLimitIncreaseModalVisible}
      />
    </header>
  );
};

HeaderMobile.propTypes = {
  t: PropTypes.func,
  userData: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  logout: PropTypes.func,
  blackHeader: PropTypes.bool,
};

export default withTranslation('common')(HeaderMobile);
