import {
  emailValidation,
  emailOrPhoneValidation,
  nameValidation,
  nonRequiredNameValidation,
  nameWithNumberValidation,
} from 'utils/validations';
import { i18n } from 'language/i18n';

export const minLengthValidator = (value, length) => ((value && value.length >= length) || !value ? Promise.resolve() : Promise.reject(i18n.t('minCharacterErr', { length })));

export const maxLengthValidator = (value, length) => ((value && value.length <= length) || !value ? Promise.resolve() : Promise.reject(i18n.t('maxCharacterErr', { length })));

export const securityNumberValidator = (value, lengthMin, lengthMax) => (!value || value.length < lengthMin || value.length > lengthMax ? Promise.reject(i18n.t('securityNumberErr', { lengthMin, lengthMax })) : Promise.resolve());

export const requiredValidator = (rule, value) => (value ? Promise.resolve() : Promise.reject(i18n.t('cantBeEmpty')));

export const emailValidator = (rule, value) => (emailValidation(value) ? Promise.resolve() : Promise.reject(i18n.t('checkEmailAddress')));

export const passwordValidator = (rule, value) => (value && value.length > 5 ? Promise.resolve() : Promise.reject(i18n.t('passwordMinCharacterErr')));

export const emailOrPhoneValidator = (rule, value) => (emailOrPhoneValidation(value) ? Promise.resolve() : Promise.reject(i18n.t('checkEmailOrPhone')));

export const nameValidator = (rule, value) => (nameValidation(value) ? Promise.resolve() : Promise.reject(i18n.t('checkName')));

export const nonRequiredNameValidator = (rule, value) => (nonRequiredNameValidation(value) ? Promise.resolve() : Promise.reject(i18n.t('checkName')));

export const nameWithNumberValidator = (rule, value) => (nameWithNumberValidation(value) ? Promise.resolve() : Promise.reject(i18n.t('checkNameWithNumber')));
