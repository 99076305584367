import React, {
  useState, useContext, useRef, createRef,
} from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'language/i18n';
import {
  TextInput, Select, Form, Checkbox, Button, PhoneInput,
} from 'components/common';
import { newsLetter } from 'models/source';
import { StatusContext } from 'contexts/statusContext';
import { USER_TYPE } from 'constants/common';
import { emailValidator, nameValidator } from 'utils/formValidators';

export const newsletterFormScrollRef = createRef();

const FooterForm = ({ t }) => {
  const { setErrorState } = useContext(StatusContext);

  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [reasonForUse, setReasonForUse] = useState('');
  const [agreement, setAgreement] = useState(false);

  const [onResponse, setOnResponse] = useState(false);
  const [formRef, setFormRef] = useState(useRef());

  const onFormSubmit = async () => {
    if (!onResponse) {
      try {
        setOnResponse(true);
        await newsLetter({
          fullName, company, email, phone, type: reasonForUse,
        });
        formRef.setFieldsValue({
          fullName: '', company: '', email: '', phone: '', reasonForUse: '', agreement: false,
        });
        setFullName('');
        setCompany('');
        setEmail('');
        setPhone('+90');
        setReasonForUse('');
        setAgreement(false);
        setErrorState({
          title: t('successful'),
          text: 'Talebiniz başarılı bir şekilde bize ulaşmıştır.',
          buttonText: t('close'),
          type: 'success',
          onClickButton: () => setErrorState({}),
        });
      } catch (error) {
        setErrorState({
          title: t('alert'),
          text: error.message,
          buttonText: t('close'),
          onClickButton: () => setErrorState({}),
        });
      } finally {
        setOnResponse(false);
      }
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFormSubmit}
      formRef={(ref) => setFormRef(ref)}
    >
      <div ref={newsletterFormScrollRef} style={{ position: 'relative', top: '-150px' }} />
      <div className="text-center">
        <h2 className="h4 text-white">E-BÜLTEN ÜYELİĞİ</h2>
      </div>
      <div className="row">
        <div className="col-15">
          <TextInput
            name="fullName"
            placeholder={t('fullName')}
            value={fullName}
            onChange={({ value }) => setFullName(value)}
            validator={nameValidator}
            required
          />
        </div>
        <div className="col-15 mt-3">
          <TextInput
            name="company"
            placeholder={t('company')}
            value={company}
            onChange={({ value }) => setCompany(value)}
            required
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-15 col-sm-15">
          <PhoneInput
            name="phone"
            label={t('phoneNumber')}
            value={phone}
            onChange={({ value }) => setPhone(value)}
            required
            mobileNumber
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <TextInput
            name="email"
            type="email"
            placeholder={t('email')}
            value={email}
            onChange={({ value }) => setEmail(value)}
            validator={emailValidator}
            required
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <Select
            name="reasonForUse"
            value={reasonForUse}
            placeholder="Niyetiniz"
            onChange={({ value }) => setReasonForUse(value)}
            options={[
              { value: USER_TYPE.BUYER, text: 'Alıcıyım' },
              { value: USER_TYPE.SELLER, text: 'Satıcıyım' },
            ]}
            required
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col footer-form-checkbox">
          <Checkbox
            name="agreement"
            value={agreement}
            onChange={({ value }) => setAgreement(value)}
            text={(
              <span className="im-checkbox__text">
                iyiMakina.com tarafından ileti gönderilmesini, bilgilerimin bu amaçla saklanmasını ve gönderime ilişkin hizmet alınan taraflarla paylaşılmasını haklarım saklı kalmak kaydı ile kabul ediyorum.
              </span>
            )}
            required
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <Button
            htmlType="submit"
            className="im-button im-button--primary im-button--fluid im-button--center"
            label={t('send')}
            loading={onResponse}
          />
        </div>
      </div>
    </Form>
  );
};

FooterForm.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('common')(FooterForm);
