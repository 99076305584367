import _ from 'lodash';

export const emailValidation = (value) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/;
  return re.test(value);
};

export const emailOrPhoneValidation = (value) => {
  const EMAIL_OR_PHONE_REGEX = /^((([a-z0-9_.+-]+)@[a-z0-9-]+\.[a-z0-9-.]{2,})|(\+?(90|0)?5\d{9}))$/;
  return EMAIL_OR_PHONE_REGEX.test(value);
};

export const nameValidation = (value) => {
  const NAME_REGEX = /^([A-ZĞÜŞİÖÇ][a-zğüşıöç]+)(?: [A-ZĞÜŞİÖÇ][a-zğüşıöç]+)*$/;
  return NAME_REGEX.test(value);
};

export const nonRequiredNameValidation = (value) => {
  const NAME_REGEX = /^$|^([A-ZĞÜŞİÖÇ][a-zğüşıöç]+)(?: [A-ZĞÜŞİÖÇ][a-zğüşıöç]+)*$/;
  return NAME_REGEX.test(value);
};

export const nameWithNumberValidation = (value) => {
  const NAME_WITH_NUMBER_REGEX = /^[A-ZĞÜŞİÖÇ0-9][A-ZĞÜŞİÖÇa-zğüşıöç0-9_/-]*(?: [A-ZĞÜŞİÖÇ0-9][A-ZĞÜŞİÖÇa-zğüşıöç0-9_/-]*)*$/;
  return NAME_WITH_NUMBER_REGEX.test(value);
};

export const checkEmpty = (array) => {
  let empty = false;

  _.forEach(array, (data) => {
    if (!_.isBoolean(data) && !_.isNumber(data)) {
      if (_.isEmpty(data)) {
        empty = true;
      }
    }
  });

  return empty;
};
